import { render, staticRenderFns } from "./SkillMatch.vue?vue&type=template&id=602d3cde&scoped=true&"
import script from "./SkillMatch.vue?vue&type=script&lang=ts&"
export * from "./SkillMatch.vue?vue&type=script&lang=ts&"
import style0 from "./SkillMatch.vue?vue&type=style&index=0&id=602d3cde&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "602d3cde",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
